import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AdminModalButton } from "../AdminModalButton.tsx";
import { AInput } from "../form/Fields.tsx";
import { referralShareCodeMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface ReferralShareCodeModalProps {
    item: GQAccountItem;
    value: string;
}

export function ReferralShareCodeButton(props: ReferralShareCodeModalProps) {
    return (
        <AdminModalButton label={props.value} isDeleted={props.item.is_deleted}>
            <ReferralShareCodeModalContent {...props} />
        </AdminModalButton>
    );
}

function ReferralShareCodeModalContent({ item, value }: ReferralShareCodeModalProps) {
    const modifyMutation = useStandardMutation(referralShareCodeMutation);
    const [code, setCode] = useState<string>(String(value));
    const { id } = item;

    async function onSubmit() {
        await modifyMutation.mutateAsync({
            body: {
                billing_account_id: Number(id),
                code: code,
            },
        });
    }

    return (
        <WModalContent title="Update referral share code" label="Update" modalAction={async () => await onSubmit()}>
            <WTextField autoFocus className="max-w-24rem" wide label="Referral share code" onChange={setCode}>
                <AInput value={code} />
            </WTextField>
        </WModalContent>
    );
}
