import AT from "../AdminTable.module.css";

import { useQuery } from "@apollo/client";
import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { RestrictionLevel } from "@warrenio/api-spec/spec.oats.gen";
import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import { keys } from "remeda";
import type { SetNonNullable } from "type-fest";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ShortDate } from "../../components/l10n/DateFormat.tsx";
import { configAtom } from "../../config.ts";
import { type CommonBillingAccountFields, ECommonBillingAccount } from "../../modules/billing/billingLogic.tsx";
import { Extra } from "../AdminTable.tsx";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import {
    BooleanComponentFilter,
    DeletedFilter,
    EnabledFilter,
    EnumComponentFilter,
    ExactNumberFilter,
    NumberRangeFilter,
    TextFilter,
    UnixDateRangeFilter,
} from "../filters.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { BillingAccountOrderFields, type GetBillingAccountListQuery, OrderDirection } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { currencyField, idField, numberField, userIdField } from "../table_fields/standardFields.tsx";
import { AccountsDetailView } from "./AccountsDetailView.tsx";
import { AccountsToolbar } from "./AccountsToolbar.tsx";
import { AddRemoveCreditButton, AddRemoveOngoingButton } from "./AddRemoveCreditModal.tsx";
import { AutomatedTopUpBadge } from "./AutomatedTopUpBadge.tsx";
import { BillingAccountBlock } from "./BillingAccountBlock.tsx";
import { DeleteAccountBlock } from "./DeleteAccountBlock.tsx";
import { DiscountModalButton } from "./DiscountModal.tsx";
import { InvoiceEmailSendingButton } from "./InvoiceEmailSendingModal.tsx";
import { PaymentMethod } from "./PaymentMethodButton.tsx";
import { PaymentPlanBadge } from "./PaymentPlanBadge.tsx";
import { ReferralShareCodeButton } from "./ReferralShareCodeModal.tsx";
import { levelDescriptions, RestrictionLevelBadge, RestrictionLevelButton } from "./RestrictionLevelBadge.tsx";
import { StatusBadge, StatusBadgeButton } from "./StatusBadge.tsx";
import { SuspensionReasonButton } from "./SuspensionReasonModal.tsx";
import { VatModalButton } from "./VatModal.tsx";

const GET_BILLING_ACCOUNTS = gql(/* GraphQL */ `
    query GetBillingAccountList(
        $limit: Int
        $page: Int
        $orderField: BillingAccountOrderFields
        $orderDir: OrderDirection
        $search: String
        $filters: [BillingAccountFilter!]
    ) {
        admin_billing_account_list(
            limit: $limit
            page: $page
            orderField: $orderField
            orderDir: $orderDir
            search: $search
            filters: $filters
        ) {
            items {
                ...BillingAccountBlock
                id
                email
                title
                user_id
                customer_name
                customer_phone
                customer_id_number
                reseller
                company_name
                is_active
                allow_debt
                is_recurring_payment_enabled
                restriction_level
                send_invoice_email
                discount_percentage
                vat_percentage
                suspend_reason
                credit_amount
                precalc_credit_amount
                precalc_days_in_debt
                precalc_ongoing
                created
                referral_code
                referral_share_code
                paying_by_invoice
                additional_data
                integration_id
                is_deleted
                deleted_at
                primary_card {
                    card_holder
                    card_type
                    expire_month
                    expire_year
                    id
                    is_verified
                    last4
                    type
                }
                running_totals {
                    subtotal
                    ongoing_negative_since
                    ongoing
                    discount_amount
                }
            }
            paging {
                total
            }
        }
    }
`);

export type GQAccountItem = NonNullable<GetBillingAccountListQuery["admin_billing_account_list"]["items"]>[number];

/** GraphQL version of `EBillingAccount` */
class EAdminBillingAccount extends ECommonBillingAccount {
    protected readonly _account: CommonBillingAccountFields;

    constructor(public readonly item: GQAccountItem) {
        super();
        this._account = {
            ...item,
            id: Number(item.id),
            // NB: GraphQL has all fields nullable for some unknown reason, so pretend they're not
        } as SetNonNullable<CommonBillingAccountFields>;
    }
}

/** Enum for referral fields IDs for easily filtering them out later */
enum ReferralFieldIds {
    ReferralCode = "referral_code",
    ReferralShareCode = "referral_share_code",
}

const baseAccountFields: GqlFieldsOf<GQAccountItem, BillingAccountOrderFields> = [
    gf({
        ...idField,
        id: "id",
        title: "ID",
        get: (a) => a.id,
        order: BillingAccountOrderFields.Id,
        filter: ExactNumberFilter,
    }),
    gf({
        ...userIdField,
        get: (a) => a.user_id,
        order: BillingAccountOrderFields.UserId,
    }),
    gf({
        id: "created_at",
        title: "Created At",
        get: (a) => apiUnixToDate(a.created),
        order: BillingAccountOrderFields.Created,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "deleted_at",
        title: "Deleted At",
        get: (a) => (typeof a.deleted_at === "number" ? apiUnixToDate(a.deleted_at) : null),
        order: BillingAccountOrderFields.DeletedAt,
        filter: UnixDateRangeFilter,
        hidden: true,
    }),
    gf({
        id: "billing_account",
        title: "Billing Account",
        get: (a) => a,
        render: BillingAccountBlock,
    }),
    gf({
        id: "title",
        title: "Title",
        get: (a) => a.title,
        order: BillingAccountOrderFields.Title,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "customer_name",
        title: "Customer Name",
        get: (a) => a.customer_name,
        order: BillingAccountOrderFields.CustomerName,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "customer_phone",
        title: "Customer Phone",
        get: (a) => a.customer_phone,
        order: BillingAccountOrderFields.CustomerPhone,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "customer_id_number",
        title: "Customer\nID Number",
        get: (a) => a.customer_id_number,
        order: BillingAccountOrderFields.CustomerIdNumber,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "company_name",
        title: "Company Name",
        get: (a) => a.company_name,
        order: BillingAccountOrderFields.CompanyName,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "email",
        title: "Invoice Email",
        get: (a) => a.email,
        order: BillingAccountOrderFields.Email,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        ...currencyField,
        id: "usage_charges",
        title: "Usage\nCharges",
        get: (a) => a.running_totals?.subtotal,
        order: BillingAccountOrderFields.TotalsSubtotal,
        filter: NumberRangeFilter,
    }),
    gf({
        ...currencyField,
        id: "precalc_credit_amount",
        title: "Credit",
        get: (a) => a.precalc_credit_amount,
        render: AddRemoveCreditButton,
        order: BillingAccountOrderFields.PrecalcCreditAmount,
        filter: NumberRangeFilter,
        hidden: true,
    }),
    gf({
        ...currencyField,
        id: "ongoing_balance",
        title: "Ongoing\nBalance",
        get: (a) => a.running_totals?.ongoing ?? 0,
        render: AddRemoveOngoingButton,
        order: BillingAccountOrderFields.PrecalcOngoing,
        filter: NumberRangeFilter,
    }),
    gf({
        id: "days_in_debt",
        title: "Days\nIn Debt",
        get: (a) => a.precalc_days_in_debt,
        order: BillingAccountOrderFields.PrecalcDaysInDebt,
        render: ({ value, item }) => {
            const since = item.running_totals?.ongoing_negative_since;
            return <Extra value={value ? value : null}>{!!since && <ShortDate date={apiUnixToDate(since)} />}</Extra>;
        },
        filter: NumberRangeFilter,
    }),
    gf({
        id: "allow_debt",
        title: "Payment Plan",
        get: (a) => a.allow_debt,
        render: PaymentPlanBadge,
        order: BillingAccountOrderFields.AllowDebt,
        filter: () => <BooleanComponentFilter component={PaymentPlanBadge} />,
    }),
    gf({
        id: "payment_method",
        title: "Payment Method",
        get: (a) => new EAdminBillingAccount(a),
        render: PaymentMethod,
    }),
    gf({
        id: "is_recurring_payment_enabled",
        title: "Automated Top Up",
        get: (a) => a.is_recurring_payment_enabled,
        render: AutomatedTopUpBadge,
        order: BillingAccountOrderFields.IsRecurringPaymentEnabled,
        filter: EnabledFilter,
        hidden: true,
    }),
    gf({
        id: "send_invoice_email",
        title: "Invoice Email Sending",
        get: (a) => a.send_invoice_email,
        render: InvoiceEmailSendingButton,
        order: BillingAccountOrderFields.SendInvoiceEmail,
        filter: EnabledFilter,
        hidden: true,
    }),
    gf({
        id: "discount_percentage",
        title: "Discount %",
        get: (a) => a.discount_percentage,
        render: DiscountModalButton,
        order: BillingAccountOrderFields.DiscountPercentage,
        filter: NumberRangeFilter,
        hidden: true,
    }),
    gf({
        ...numberField,
        id: "vat_percentage",
        title: "VAT %",
        get: (a) => a.vat_percentage,
        render: VatModalButton,
        order: BillingAccountOrderFields.VatPercentage,
        filter: NumberRangeFilter,
        hidden: true,
    }),
    gf({
        id: "restriction_level",
        title: "Restriction Level",
        get: (a) => a.restriction_level as RestrictionLevel,
        order: BillingAccountOrderFields.RestrictionLevel,
        render: RestrictionLevelButton,
        filter: () => <EnumComponentFilter values={keys(levelDescriptions)} component={RestrictionLevelBadge} />,
    }),
    gf({
        id: "status",
        title: "Status",
        get: (a) => a.is_active,
        render: StatusBadgeButton,
        order: BillingAccountOrderFields.IsActive,
        filter: () => <BooleanComponentFilter component={StatusBadge} />,
    }),
    gf({
        id: ReferralFieldIds.ReferralCode,
        title: "Campaign Codes Used",
        get: (a) => a.referral_code,
        order: BillingAccountOrderFields.ReferralCode,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: ReferralFieldIds.ReferralShareCode,
        title: "Referral Share Code",
        get: (a) => a.referral_share_code,
        render: ReferralShareCodeButton,
        order: BillingAccountOrderFields.ReferralShareCode,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "suspend_reason",
        title: "Suspend Reason",
        get: (a) => a.suspend_reason,
        render: ({ value, item }) => <SuspensionReasonButton item={item} value={value} />,
        order: BillingAccountOrderFields.SuspendReason,
        filter: TextFilter,
    }),
    gf({
        id: "integration_id",
        title: "Integration ID",
        get: (a) => a.integration_id,
        order: BillingAccountOrderFields.IntegrationId,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "is_deleted",
        title: "Deleted",
        get: (a) => a.is_deleted,
        render: ({ value }) => (value ? "DELETED" : "NOT DELETED"),
        order: BillingAccountOrderFields.IsDeleted,
        filter: DeletedFilter,
        hidden: true,
    }),
];

const accountFieldsAtom = atom((get) => {
    const { referralCodeEnabled } = get(configAtom);
    const referralFields = Object.values(ReferralFieldIds);
    // Filter out referral fields if referral code is not enabled
    return referralCodeEnabled ? baseAccountFields : baseAccountFields.filter((f) => !referralFields.includes(f.id));
});

export function AccountsTable() {
    const accountFields = useAtomValue(accountFieldsAtom);

    return (
        <GraphqlTable<GQAccountItem, BillingAccountOrderFields>
            title="Billing Accounts"
            fields={accountFields}
            defaults={{
                orderField: BillingAccountOrderFields.Created,
                orderDir: OrderDirection.Desc,
            }}
            getId={(item) => item.id}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_BILLING_ACCOUNTS, { variables });
                return extractData(q, (d) => d.admin_billing_account_list);
            }}
            getRowClassname={(item) =>
                item.is_deleted ? AT.Accounts_Deleted : !item.is_active ? AT.Accounts_Suspended : undefined
            }
            actionButtons={(item) => (
                <DeleteAccountBlock
                    item={item}
                    button={<WModalButton inTable label="Delete" size="xs" icon="jp-trash-icon" />}
                />
            )}
            renderToolbar={(item) => <AccountsToolbar item={item} />}
            renderDetail={(item) => <AccountsDetailView item={item} />}
        />
    );
}
