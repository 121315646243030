import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { OngoingBalance } from "../../modules/billing/OngoingBalance.tsx";
import { AdminModalButton } from "../AdminModalButton.tsx";
import { AInput } from "../form/Fields.tsx";
import { creditAmountMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface AddRemoveCreditModalProps {
    item: GQAccountItem;
    value: number;
}

export function AddRemoveCreditButton(props: AddRemoveCreditModalProps) {
    return (
        <AdminModalButton label={<CurrencyBalance value={props.value} />} isDeleted={props.item.is_deleted}>
            <AddRemoveCreditModalContent {...props} />
        </AdminModalButton>
    );
}

export function AddRemoveOngoingButton(props: AddRemoveCreditModalProps) {
    const { value } = props;

    return (
        <AdminModalButton label={<OngoingBalance value={value} />} isDeleted={props.item.is_deleted}>
            <AddRemoveCreditModalContent {...props} />
        </AdminModalButton>
    );
}

function AddRemoveCreditModalContent({ item }: AddRemoveCreditModalProps) {
    const modifyMutation = useStandardMutation(creditAmountMutation);
    const [value, setValue] = useState<string | undefined>(undefined);
    const { id, running_totals, precalc_credit_amount } = item;

    async function onSubmit() {
        await modifyMutation.mutateAsync({
            body: {
                billing_account_id: Number(id),
                amount: value ? Number(value) : 0,
                description: "Credit from admin UI",
            },
        });
    }

    return (
        <WModalContent title="Add or remove credit" label="Submit" modalAction={async () => await onSubmit()}>
            <DetailsTable>
                <DetailsTableRow title="Usage charges:">
                    <CurrencyBalance value={running_totals?.subtotal ?? 0} />
                </DetailsTableRow>
                <DetailsTableRow title="Available credit:">
                    <CurrencyBalance value={precalc_credit_amount} />
                </DetailsTableRow>
                <DetailsTableRow title="Ongoing balance:">
                    <OngoingBalance value={running_totals?.ongoing ?? 0} />
                </DetailsTableRow>
            </DetailsTable>

            <WTextField autoFocus className="max-w-24rem" wide label="Credit amount" onChange={setValue}>
                <AInput value={value} />
                <p className="font-size-small color-muted pt-1">
                    Enter the amount of credit you wish to add to the current credit. To remove credit, enter amount
                    with negative value.
                </p>
            </WTextField>
        </WModalContent>
    );
}
