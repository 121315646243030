import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AdminModalButton } from "../AdminModalButton.tsx";
import { AInput } from "../form/Fields.tsx";
import { discountPercentageMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface DiscountModalModalProps {
    item: GQAccountItem;
    value: number;
}

export function DiscountModalButton(props: DiscountModalModalProps) {
    return (
        <AdminModalButton label={`${props.value}%`} isDeleted={props.item.is_deleted}>
            <DiscountModalModalContent {...props} />
        </AdminModalButton>
    );
}

function DiscountModalModalContent({ item, value }: DiscountModalModalProps) {
    const modifyMutation = useStandardMutation(discountPercentageMutation);
    const [discount, setDiscount] = useState<string | undefined>(String(value));
    const { id } = item;

    async function onSubmit() {
        await modifyMutation.mutateAsync({
            body: {
                billing_account_id: Number(id),
                discount_percentage: discount ? Number(discount) : 0,
            },
        });
    }

    return (
        <WModalContent
            title={`Set discount percentage for billing account ${id}`}
            label="Submit"
            modalAction={async () => await onSubmit()}
        >
            <WTextField autoFocus className="max-w-24rem" wide label="Discount %" onChange={setDiscount}>
                <AInput value={discount} />
            </WTextField>
        </WModalContent>
    );
}
