import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { useAtomValue } from "jotai/react";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import type { ECommonBillingAccount } from "../../modules/billing/billingLogic.tsx";
import { accountLinkedMethodsAtom } from "../../modules/billing/BoundMethod.ts";
import { cn } from "../../utils/classNames.ts";
import { AdminModalButton, type AdminModalButtonProps } from "../AdminModalButton.tsx";
import { Missing } from "../AdminTable.tsx";
import { disableInvoiceMutation, enableInvoiceMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface PaymentMethodModalProps {
    item: GQAccountItem;
    value: ECommonBillingAccount;
}

interface PaymentMethodButtonProps extends PaymentMethodModalProps {
    label: AdminModalButtonProps["label"];
    isEnabled: boolean;
}

function MethodList({ value }: { value: ECommonBillingAccount }) {
    const linkedMethods = useAtomValue(accountLinkedMethodsAtom(value.account));
    if (linkedMethods.length === 0) {
        return <Missing />;
    }
    return linkedMethods.map((m) => (
        <div key={m.id} className={cn("text-nowrap", linkedMethods.length > 2 && "font-size-small")}>
            {/* <PaymentLinkIcon icon={m.method.id} />  */}
            {m.method.name}
        </div>
    ));
}

export function PaymentMethodButton(props: PaymentMethodButtonProps) {
    return (
        <AdminModalButton label={props.label}>
            <PaymentMethodModalContent {...props} />
        </AdminModalButton>
    );
}

export function PaymentMethod(props: PaymentMethodModalProps) {
    const { value } = props;

    const inner = (() => {
        switch (value.payType) {
            case "invoice":
            case "invoice_pending": {
                const label =
                    value.payType === "invoice" ? "Invoice" : <span className="text-warning">Invoice (pending)</span>;
                return (
                    <>
                        <WTooltip text="Disable Invoice Payment">
                            <PaymentMethodButton isEnabled={value.isInvoiceVerified} label={label} {...props} />
                        </WTooltip>

                        {value.account.allow_debt && !value.account.send_invoice_email && (
                            <div className="color-error font-size-small whitespace-nowrap">Invoice emails disabled</div>
                        )}
                    </>
                );
            }
            case "card":
                return <span>Credit Card</span>;
            case "prepay": {
                return <MethodList value={value} />;
            }
            default:
                exhaustiveSwitchCheck(value.payType);
        }
    })();
    return <div className={cn(value.account.is_deleted && "line-through")}>{inner}</div>;
}

function PaymentMethodModalContent({ item, isEnabled }: PaymentMethodButtonProps) {
    const enableMutation = useStandardMutation(enableInvoiceMutation);
    const disableMutation = useStandardMutation(disableInvoiceMutation);
    const { id } = item;

    async function onSubmit() {
        if (!isEnabled) {
            await enableMutation.mutateAsync({
                body: {
                    billing_account_id: Number(id),
                },
            });
        } else {
            await disableMutation.mutateAsync({
                body: {
                    billing_account_id: Number(id),
                },
            });
        }
    }

    const label = !isEnabled ? "Enable" : "Disable";
    return (
        <WModalContent title="Invoice payment" label={label} modalAction={async () => await onSubmit()}>
            <p>{label} invoice payment.</p>
        </WModalContent>
    );
}
