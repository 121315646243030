import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AdminModalButton } from "../AdminModalButton.tsx";
import { invoiceEmailSendingMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface InvoiceEmailSendingModalProps {
    item: GQAccountItem;
    value: boolean;
}

export function InvoiceEmailSendingButton(props: InvoiceEmailSendingModalProps) {
    return (
        <AdminModalButton label={props.value ? "Send automatically" : "Do not send"} isDeleted={props.item.is_deleted}>
            <InvoiceEmailSendingModalContent {...props} />
        </AdminModalButton>
    );
}

function InvoiceEmailSendingModalContent({ value, item }: InvoiceEmailSendingModalProps) {
    const modifyMutation = useStandardMutation(invoiceEmailSendingMutation);

    async function onSubmit() {
        await modifyMutation.mutateAsync({
            body: {
                billing_account_id: Number(item.id),
                send_invoice_email: !value,
            },
        });
    }

    return (
        <WModalContent
            title="Automatic Sending of Invoice Email"
            label={value ? "Disable" : "Enable"}
            modalAction={async () => await onSubmit()}
        >
            {value ? "Disable sending invoice email?" : "Enable sending invoice email?"}
        </WModalContent>
    );
}
