import type { RestrictionLevel } from "@warrenio/api-spec/spec.oats.gen";
import { Badge, type BadgeProps } from "../../components/Badge.tsx";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { useConfig } from "../../config.ts";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { restrictionLevelMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface RestrictionLevelModalProps {
    item: GQAccountItem;
    value: RestrictionLevel;
}

export function RestrictionLevelBadge({ value }: { value: RestrictionLevel }) {
    const { label, description, color } = levelDescriptions[value];
    return (
        <WTooltip text={description}>
            <Badge color={color}>{label}</Badge>
        </WTooltip>
    );
}

export function RestrictionLevelButton(props: RestrictionLevelModalProps) {
    const { value } = props;
    const { label, description, color } = levelDescriptions[value];
    const isEditable = value === "CLEAR" || value === "LIMITED";

    return (
        <WTooltip text={description}>
            {isEditable ? (
                <WModal
                    button={
                        <EditableButton action={undefined}>
                            <Badge color={color}>{label}</Badge>
                        </EditableButton>
                    }
                >
                    <RestrictionLevelModalContent {...props} />
                </WModal>
            ) : (
                <Badge color={color}>{label}</Badge>
            )}
        </WTooltip>
    );
}

function RestrictionLevelModalContent({ item, value }: RestrictionLevelModalProps) {
    const updateMutation = useStandardMutation(restrictionLevelMutation);
    const { limitedBillingAccountConfig } = useConfig();

    const { vcpu, ram_mb } = limitedBillingAccountConfig;
    const { id, title } = item;
    const isClear = value === "CLEAR";
    const levelOverride = isClear ? "LIMITED" : "CLEAR";

    async function onSubmit() {
        await updateMutation.mutateAsync({
            id: Number(id),
            level_override: levelOverride,
        });
    }

    return (
        <WModalContent
            title={isClear ? "Add Limits" : "Remove Limits"}
            label={isClear ? "Add" : "Remove"}
            autoFocus
            modalAction={async () => await onSubmit()}
        >
            {isClear ? (
                <p>
                    Adding limits for billing account <b>{title}</b> means that this account will have a limit of {vcpu}{" "}
                    CPUs and {ram_mb} MB RAM when creating resources.
                </p>
            ) : (
                <p>
                    Removing limits from billing account <b>{title}</b> means that this account will be able to create
                    and run an unlimited amount of resources.
                </p>
            )}
        </WModalContent>
    );
}

export const levelDescriptions: Record<
    RestrictionLevel,
    {
        label: string;
        description: string;
        color: BadgeProps["color"];
    }
> = {
    CLEAR: {
        label: "Clear",
        description: "No restrictions",
        color: "success",
    },
    LIMITED: {
        label: "Limited",
        description: "Resource allocation is limited",
        color: "primary",
    },
    FROZEN: {
        label: "Frozen",
        description: "Resource allocation is forbidden. Existing resources are stopped.",
        color: "error",
    },
    TERMINATED: {
        label: "Terminated",
        description: "Resource allocation is forbidden. Existing resources are deleted.",
        color: "error",
    },
};
