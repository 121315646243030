import { type ReactNode, useState } from "react";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { accountClosingInvoicesMutation, deleteMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";

interface DeleteAccountModalProps {
    item: GQAccountItem;
    button: ReactNode;
}

function DeleteAccountModalContent({ item }: DeleteAccountModalProps) {
    const closingMutation = useStandardMutation(accountClosingInvoicesMutation);
    const removeMutation = useStandardMutation(deleteMutation);
    const [credit, setCredit] = useState<boolean>(false);
    const [allow, setAllow] = useState<boolean>(false);

    const { id, title, running_totals } = item;
    const ongoing = running_totals?.ongoing ?? 0;

    async function onSubmit() {
        await closingMutation.mutateAsync({
            id: Number(id),
        });
        await removeMutation.mutateAsync({
            body: {
                billing_account_id: Number(id),
            },
        });
    }

    return (
        <WModalContent
            isActionDisabled={!allow || (!!ongoing && !credit)}
            title="Delete Billing Account"
            label="Delete"
            autoFocus
            modalAction={async () => await onSubmit()}
        >
            <p>
                Deleting billing account "<b>{title}</b>" will trigger the following actions:
            </p>
            <ul>
                <li>deletion of any resources linked to this billing account,</li>
                <li>any pending usage charges will be calculated and added to the final balance,</li>
                <li>all unpaid amounts will be cleared with a credit note,</li>
                <li>any credit left on the billing account will be deleted,</li>
                <li>billing account will not be visible for the user anymore.</li>
            </ul>

            {!!ongoing && (
                <div className="pb-2">
                    <WCheckbox isSelected={credit} onChange={setCredit}>
                        I understand that user will lose{" "}
                        <b>
                            <CurrencyBalance value={ongoing} />
                        </b>{" "}
                        worth of credit.
                    </WCheckbox>
                </div>
            )}

            <WCheckbox isSelected={allow} onChange={setAllow}>
                I understand that these actions are not reversible.
            </WCheckbox>
        </WModalContent>
    );
}

export function DeleteAccountBlock(props: DeleteAccountModalProps) {
    return (
        <WModal button={props.button}>
            <DeleteAccountModalContent {...props} />
        </WModal>
    );
}
