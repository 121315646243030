import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { ContentPane } from "../../components/ContentPane.tsx";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { Separator } from "../../components/Separator.tsx";
import { Spacer } from "../../components/Spacer.tsx";
import { ViewTitle } from "../../components/Title.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow, type DetailsTableRowProps } from "../../components/table/DetailsTableRow.tsx";
import { useConfig } from "../../config.ts";
import { OngoingBalance } from "../../modules/billing/OngoingBalance.tsx";
import { baFieldsConfigAtom } from "../../modules/billing/billingAccount/fieldsConfig.ts";
import { baLabels } from "../../modules/billing/billingAccount/fieldsInfo.ts";
import { CountryFlag } from "../../modules/location/CountryFlag.tsx";
import { getIso2CountryInfo } from "../../modules/location/locationCountry.ts";
import { UserPane } from "../users/UserDetails.tsx";
import type { GQAccountItem } from "./AccountsTable.tsx";

export function AccountsDetailView({ item }: { item: GQAccountItem }) {
    return (
        <>
            <BillingAccountDetails account={item} />
            <Separator />
            <UserPane value={String(item.user_id)} />

            {/* <ContentPane>
                <AutoRender value={item} />
            </ContentPane> */}
        </>
    );
}

function BillingAccountDetails({ account }: { account: GQAccountItem }) {
    const { siteCountry } = useConfig();
    const { id, title, discount_percentage, running_totals } = account;
    const ongoing = running_totals?.ongoing ?? 0;

    const countryName = siteCountry && getIso2CountryInfo(siteCountry)?.name;
    const country3code = siteCountry && getIso2CountryInfo(siteCountry)?.code.iso3;

    return (
        <ContentPane className="VStack gap-4">
            <ViewTitle
                title={title ?? ""}
                // TODO: Remove global margins from all headings and instead use container-based layouts (eg. gap)
                // (otherwise it's impossible to lay out optional (nullable) components nicely without passing additional margin properties into every one of them)
                className="important:pb-0 important:min-h-auto"
            />

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="Account ID:">{id}</DetailsTableRow>

                    <DetailsTableRow title="Account name:">{title}</DetailsTableRow>

                    <DetailsTableRow title="Type:">
                        <ResourceWithIcon type="billing_account" />
                    </DetailsTableRow>

                    <BFieldRow field="company_name" account={account} />
                    <BFieldRow field="company_reg_code" account={account} />
                    <BFieldRow field="company_vat_number" account={account} />
                    <BFieldRow field="email" account={account} />
                    <BFieldRow field="customer_name" account={account} />
                    <BFieldRow field="customer_phone" account={account} />
                    <BFieldRow field="customer_id_number" account={account} />
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    <BFieldRow field="country">
                        <div className="flex gap-0.5rem">
                            <CountryFlag code={country3code ?? ""} />
                            {countryName}
                        </div>
                    </BFieldRow>
                    <BFieldRow field="county" account={account} />
                    <BFieldRow field="city" account={account} />
                    <BFieldRow field="address_line1" account={account} />
                    <BFieldRow field="post_index" account={account} />

                    <DetailsTableRow />

                    <DetailsTableRow
                        title="Ongoing balance:"
                        tooltip="Hourly updated usage for current billing period."
                    >
                        <OngoingBalance value={ongoing} bold />
                    </DetailsTableRow>

                    {discount_percentage > 0 && (
                        <DetailsTableRow
                            title="Discount:"
                            tooltip="A discount has been applied for all services under this billing account."
                        >
                            <b className="color-success">-{discount_percentage}%</b>
                        </DetailsTableRow>
                    )}

                    <DetailsTableRow title="Campaign code:">
                        {account.referral_code ? account.referral_code : "-"}
                    </DetailsTableRow>
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

interface BFieldRowProps extends DetailsTableRowProps {
    title?: ReactNode;
    children?: ReactNode;
    field: keyof typeof baLabels;
    account?: GQAccountItem;
}

function BFieldRow({ title, children, field, account, ...props }: BFieldRowProps) {
    const fieldsConfig = useAtomValue(baFieldsConfigAtom);

    if (!fieldsConfig[field]?.visible) {
        return null;
    }

    let displayedValue: ReactNode;
    if (children === undefined && account != null) {
        const value: unknown = account[field as keyof GQAccountItem];
        displayedValue = value != null && value !== "" ? String(value as any) : "-";
    } else {
        displayedValue = children;
    }

    return (
        <DetailsTableRow title={title ?? `${baLabels[field]}:`} {...props}>
            {displayedValue}
        </DetailsTableRow>
    );
}
