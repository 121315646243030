import { useState } from "react";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { AdminModalButton } from "../AdminModalButton.tsx";
import { suspensionReasonMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";
import { SuspensionReasonTextBlock } from "./SuspensionReasonTextBlock.tsx";

interface SuspensionReasonModalProps {
    item: GQAccountItem;
    value: string | null | undefined;
}

export function SuspensionReasonButton(props: SuspensionReasonModalProps) {
    return props.value ? (
        <AdminModalButton label={props.value}>
            <SuspensionReasonModalContent {...props} />
        </AdminModalButton>
    ) : (
        "-"
    );
}

function SuspensionReasonModalContent({ item, value }: SuspensionReasonModalProps) {
    const modifyMutation = useStandardMutation(suspensionReasonMutation);
    const [reason, setReason] = useState(value);
    const { id } = item;

    async function onSubmit() {
        await modifyMutation.mutateAsync({
            body: {
                billing_account_id: Number(id),
                reason: reason ?? "",
            },
        });
    }

    return (
        <WModalContent title="Update suspend reason" label="Update" modalAction={async () => await onSubmit()}>
            <SuspensionReasonTextBlock value={value ?? ""} onChange={setReason} />
        </WModalContent>
    );
}
