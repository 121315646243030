import { useState } from "react";
import { Badge } from "../../components/Badge.tsx";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { activateMutation, deactivateMutation, suspensionReasonMutation } from "./accountsQuery.ts";
import type { GQAccountItem } from "./AccountsTable.tsx";
import { SuspensionReasonTextBlock } from "./SuspensionReasonTextBlock.tsx";

interface StatusBadgeModalProps {
    item: GQAccountItem;
    value: boolean;
}

export function StatusBadge({ value }: { value: boolean }) {
    return <Badge color={value ? "success" : "error"}>{value ? "Active" : "Suspended"}</Badge>;
}

export function StatusBadgeButton(props: StatusBadgeModalProps) {
    const { value } = props;

    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <Badge color={value ? "success" : "error"}>{value ? "Active" : "Suspended"}</Badge>
                </EditableButton>
            }
        >
            <StatusBadgeModalContent {...props} />
        </WModal>
    );
}

function StatusBadgeModalContent({ item, value }: StatusBadgeModalProps) {
    const activateBaMutation = useStandardMutation(activateMutation);
    const deactivateBaMutation = useStandardMutation(deactivateMutation);
    const modifyMutation = useStandardMutation(suspensionReasonMutation);
    const { id } = item;
    const [reason, setReason] = useState("");

    async function onSubmit() {
        if (value) {
            await deactivateBaMutation.mutateAsync({
                body: {
                    billing_account_id: Number(id),
                },
            });

            if (reason) {
                await modifyMutation.mutateAsync({
                    body: {
                        billing_account_id: Number(id),
                        reason: reason ?? "",
                    },
                });
            }
        } else {
            await activateBaMutation.mutateAsync({
                body: {
                    billing_account_id: Number(id),
                },
            });
        }
    }

    return (
        <WModalContent
            title={value ? "Suspend Billing Account" : "Activate Billing Account"}
            label={value ? "Suspend" : "Activate"}
            modalAction={async () => await onSubmit()}
        >
            {value ? (
                <>
                    <p>
                        Suspending billing account will stop all it's resources automatically, customer will not be able
                        to start them again.
                    </p>
                    <SuspensionReasonTextBlock value={reason} onChange={setReason} />
                </>
            ) : (
                <p>Activating billing account will remove the lock from it. VMs need to be started manually.</p>
            )}
        </WModalContent>
    );
}
