import { WModalButton } from "../../components/button/WToolButton.tsx";
import { Toolbar } from "../../components/Toolbar.tsx";
import type { GQAccountItem } from "./AccountsTable.tsx";
import { DeleteAccountBlock } from "./DeleteAccountBlock.tsx";

export function AccountsToolbar({ item }: { item: GQAccountItem }) {
    return (
        <Toolbar>
            <DeleteAccountBlock item={item} button={<WModalButton label="Delete" size="md" icon="jp-trash-icon" />} />
        </Toolbar>
    );
}
