import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { AInput } from "../form/Fields.tsx";

export function SuspensionReasonTextBlock({ value, onChange }: { value: string; onChange: (value: string) => void }) {
    const [reason, setReason] = useState(value);

    function onReason(value: string) {
        setReason(value);
        onChange(value);
    }

    return (
        <WTextField
            aria-label="Reason"
            autoFocus
            onChange={onReason}
            description="Enter reason why account will be suspended. Keep in mind that the reason will be shown to user also."
        >
            <AInput value={reason ?? ""} />
        </WTextField>
    );
}
