import { useSuspenseQuery } from "@apollo/client";
import { ContentPane } from "../../components/ContentPane.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { ContentLoadingSuspense } from "../../components/loading/Loading.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { gql } from "../graphql.gen/gql.ts";

type UserId = string;

const USER_QUERY = gql(/* GraphQL */ `
    query GetUserDetails($id: String!) {
        admin_user_details(id: $id) {
            id
            signup_site
            name
            profile_data {
                # avatar
                created_at
                # updated_at
                email
                first_name
                last_name
                # personal_id_number
                # phone_number
            }
        }
    }
`);

export function UserDetails({ value }: { value: UserId }) {
    const { data } = useSuspenseQuery(USER_QUERY, { variables: { id: value }, fetchPolicy: "cache-first" });
    const user = data?.admin_user_details;
    const created_at = user?.profile_data?.created_at ? <LongDate date={user?.profile_data?.created_at} /> : "-";

    return (
        <DetailsHolder>
            <DetailsTable>
                <DetailsTableRow title="ID:">{user?.id}</DetailsTableRow>
                <DetailsTableRow title="Name:">{user?.name}</DetailsTableRow>
                <DetailsTableRow title="Signup Site:">{user?.signup_site}</DetailsTableRow>
                <DetailsTableRow title="First Name:">{user?.profile_data?.first_name}</DetailsTableRow>
                <DetailsTableRow title="Last Name:">{user?.profile_data?.last_name}</DetailsTableRow>
                <DetailsTableRow title="Email:">{user?.profile_data?.email}</DetailsTableRow>
                <DetailsTableRow title="Created At:">{created_at}</DetailsTableRow>
            </DetailsTable>
        </DetailsHolder>
    );
}

export function UserPane({ value }: { value: UserId | undefined }) {
    if (!value) {
        return null;
    }

    return (
        <ContentPane>
            <h2 className="font-size-subtitle pb-0">User</h2>

            <ContentLoadingSuspense>
                <UserDetails value={value} />
            </ContentLoadingSuspense>
        </ContentPane>
    );
}
