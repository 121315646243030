import type {
    ActivateDeactivateBody,
    BillingAccount,
    CreditAmountBody,
    DeleteBillingAccountBody,
    DiscountPercentageBody,
    InvoiceEmailSendingBody,
    PayingByInvoiceBody,
    ReferralShareCodeBody,
    SuspensionReasonBody,
    VatPercentageBody,
} from "@warrenio/api-spec/spec.oats.gen";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";

//#region Mutations

export const creditAmountMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: CreditAmountBody }) =>
        await apiClient.POST("/payment/credit", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const invoiceEmailSendingMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: InvoiceEmailSendingBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/send_invoice_email", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const discountPercentageMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: DiscountPercentageBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/discount", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const vatPercentageMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: VatPercentageBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/vat", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const restrictionLevelMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ id, level_override }: { id: BillingAccount["id"]; level_override: string }) =>
        await apiClient.PUT("/payment/admin/billing_account/restriction_open_level_override", {
            params: { query: { billing_account_id: id, level_override: level_override } },
        }),
}));

export const referralShareCodeMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: ReferralShareCodeBody }) =>
        await apiClient.PUT("/payment/admin/billing_account/referral_share_code", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const activateMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: ActivateDeactivateBody }) =>
        await apiClient.PUT("/payment/billing_account/activate", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const deactivateMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: ActivateDeactivateBody }) =>
        await apiClient.PUT("/payment/billing_account/deactivate", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const suspensionReasonMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: SuspensionReasonBody }) =>
        await apiClient.PUT("/payment/admin/set_suspend_reason", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const accountClosingInvoicesMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ id }: { id: BillingAccount["id"] }) =>
        await apiClient.POST("/charging/admin/accountClosingInvoices", {
            params: { query: { billingAccountId: id } },
        }),
}));

export const deleteMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "delete",
    requestFn: async ({ body }: { body: DeleteBillingAccountBody }) =>
        await apiClient.DELETE("/payment/admin/billing_account", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const enableInvoiceMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: PayingByInvoiceBody }) =>
        await apiClient.POST("/payment/paying_by_invoice", {
            ...jsonEncodedBody,
            body,
        }),
}));

export const disableInvoiceMutation = adminMutation((apiClient) => ({
    entity: "billing",
    operation: "modify",
    requestFn: async ({ body }: { body: PayingByInvoiceBody }) =>
        await apiClient.DELETE("/payment/paying_by_invoice", {
            ...jsonEncodedBody,
            body,
        }),
}));
//#endregion Mutations
